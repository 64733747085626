<template>
  <img
    class="rounded-lg w-full object-cover"
    :src="imgSrc"
    @load="imgSrc = src"
    alt=""
  />
</template>


<script>
export default {
  name: "ImgFallBack",
  props: {
    src: {
      type: String,
    },
  },
  data() {
    return {
      imgSrc: "https://via.placeholder.com/150",
    };
  },
};
</script>

<style>
</style>