<template>
  <div>
    <navbar></navbar>
    <transition
      :enter-active-class="transition.leave"
      :leave-active-class="transition.enter"
      mode="out-in"
    >
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      transition: {
        enter:"",
        leave:""
      },
    };
  },
  watch: {
    $route(to, from) {
      const orderRoutes = ["engineer", "newbiesoft", "member"];
      const toIndex = orderRoutes.indexOf(to.name);
      const fromIndex = orderRoutes.indexOf(from.name);
      this.transition.leave =
        fromIndex < toIndex
          ? "animate__animated animate__fadeInRight"
          : "animate__animated animate__fadeInLeft";
      this.transition.enter =
        fromIndex < toIndex
          ? "animate__animated animate__fadeOutLeft"
          : "animate__animated animate__fadeOutRight";
    },
  },
};
</script>

<style>
</style>
