<template>
  <div class="sticky top-0 w-full z-50 bg-white py-2">
    <div class="sm:block text-center flex justify-between px-2 items-center">
      <span class="text-4xl font-bold"
        >Mehdi <span class="text-red">M</span> Bourahla</span
      >
      <span class="block sm:hidden" @click="collapseNav = !collapseNav"
        ><span class="material-icons-outlined"> menu </span></span
      >
    </div>
    <div
      v-if="collapseNav"
      class="sm:hidden flex w-auto bg-white absolute rounded-lg right-0 z-10 flex-col"
      @change="collapseNav = false"
    >
      <router-link
        class="text-xl text-center font-semibold p-2 rounded-lg"
        v-for="link in links"
        :class="{ 'bg-red text-white': link.active }"
        :key="link.to"
        :to="{ name: link.to }"
        @click.native="toggleLink"
        >{{ link.label }}</router-link
      >
    </div>

    <div
      class="sm:flex hidden flex-nowrap justify-around py-2 max-w-4xl m-auto"
    >
      <router-link
        class="text-xl text-center font-semibold p-2 rounded-lg"
        v-for="link in links"
        :class="{ 'bg-red text-white': link.active }"
        :key="link.to"
        :to="{ name: link.to }"
        @click.native="toggleLink"
        >{{ link.label }}</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  mounted() {
    this.toggleLink();
  },
  data() {
    return {
      collapseNav: false,
      links: [
        {
          to: "engineer",
          label: "AI Entousiast",
          active: true,
        },
        {
          to: "newbiesoft",
          label: "Content Creation",
          active: false,
        },
        {
          to: "member",
          label: "Voluntary",
          active: false,
        },
      ],
    };
  },
  methods: {
    toggleLink() {
      this.links.map((link) => {
        if (link.to === this.$route.name) {
          link.active = true;
        } else {
          link.active = false;
        }
      });
      this.collapseNav = false;
    },
  },
};
</script>

<style></style>
